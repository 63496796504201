<template>
  <div class="tasty-job-page" :key="componentKey">
    <div class="container">
      <div class="section">
        <div class="hero-banner">
          <img
            class="hero-banner__mobile-image"
            src="/assets/images/cms/tasty-job/hero-banner.webp"
            srcset="
              /assets/images/cms/tasty-job/hero-banner.webp 1x,
              /assets/images/cms/tasty-job/hero-banner@1.5x.webp 1.5x,
              /assets/images/cms/tasty-job/hero-banner@2x.webp 2x,
              /assets/images/cms/tasty-job/hero-banner@3x.webp 3x
            "
            sizes="(max-width: 767px) 100vw"
            alt="Tasty Job Banner"
          />
          <NoSSR>
            <Lottie
              class="hero-banner__desktop-video"
              :animation-data="bannerAnimation"
              :loop="false"
              :autoplay="true"
            />
          </NoSSR>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="section section__text">
        <p class="text text--dark-grey text--size-16">
          VARUS – це про команду, націлену на загальний результат та підтримку один одного. Працюєш ти у супермаркеті, офісі чи на складі – не має значення. Наша мета одна – стати улюбленим місцем для клієнта, і лише спільна взаємодія допомагає досягати цього.
        </p>
        <p class="text text--orange text--size-22">
          Наше призначення - стати улюбленим магазином наших клієнтів, щодня вирішуючи їхні проблеми та перевищуючи їхні очікування. Саме цим ми покращуємо життя українців і робимо їх щасливішими.
        </p>
      </div>
    </div>
    <div class="container">
      <MAnimateSection />
    </div>
    <AStatic
      :content="content"
      custom-class="tasty-job"
      ref="tastyJob"
    />
    <MTastyJobCarousel
      v-if="isLoadingCarousel"
      :slider-settings="sliderSettings"
      ref="tastyJobCarouselslider1"
      :items="slidersData.slider1"
    />
    <MTastyJobCarousel
      v-if="isLoadingCarousel"
      :slider-settings="sliderSettings"
      ref="tastyJobCarouselslider2"
      :items="slidersData.slider2"
    />
    <MTastyJobCarousel
      v-if="isLoadingCarousel"
      :slider-settings="sliderSettings"
      ref="tastyJobCarouselslider3"
      :items="slidersData.slider3"
    />
    <MTastyJobForm
      ref="tastyJobFormRef"
      :content="content"
    />
  </div>
</template>
<script>
import AStatic from 'theme/components/atoms/a-static'
import MTastyJobCarousel from 'theme/components/molecules/m-tasty-job-carousel'
import { BREAKPOINT_SM, BREAKPOINT_MD } from 'theme/helpers/breakpoints'
import MTastyJobForm from 'theme/components/molecules/m-tasty-job-form'
import MAnimateSection from 'theme/components/molecules/cms/tasty-job/m-animate-section'
import AFaq from 'theme/components/atoms/a-faq';
import Vue from 'vue'
import bannerAnimation from 'theme/assets/tasty-job-together-right.json/tasty-job-banner.json'
import NoSSR from 'vue-no-ssr'
import AVarikAnimation from 'theme/components/atoms/a-varik-animation.vue';

export default {
  name: 'TastyJob',
  components: {
    AVarikAnimation,
    AStatic,
    MTastyJobCarousel,
    MTastyJobForm,
    MAnimateSection,
    Lottie: () => process.browser ? import('lottie-web-vue') : null,
    NoSSR
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  data () {
    return {
      sliderItems: [],
      isLoadingCarousel: false,
      componentKey: 0,
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        gap: 10,
        perView: 1,
        peek: {
          before: 0,
          after: 0
        },
        breakpoints: {
          [BREAKPOINT_MD]: {
            gap: 10,
            perView: 1,
            peek: {
              before: 0,
              after: 0
            }
          },
          [BREAKPOINT_SM - 1]: {
            animationTimingFunc: 'ease-in-out',
            animationDuration: 2000,
            rewindDuration: 2000,
            perView: 1,
            peek: {
              before: 0,
              after: 0
            }
          }
        }
      },
      slidersData: {
        slider1: [],
        slider2: [],
        slider3: []
      },
      faqs: [],
      bannerAnimation,
      lottieOptions: {
        animationData: bannerAnimation,
        loop: true,
        autoplay: true
      }
    }
  },
  watch: {
    'slidersData': {
      deep: true,
      handler (val) {
        console.log('slidersData changed:', val);
        if (val.slider1.length > 0 || val.slider2.length > 0 || val.slider3.length > 0) {
          console.log('Setting isLoadingCarousel to true');
          this.isLoadingCarousel = true
        }
      }
    }
  },
  methods: {
    togetherAnimationComponent () {
      try {
        const workWithUs = this.$refs?.tastyJob?.$el?.querySelector('.work-with-us');

        if (!workWithUs) return

        const component = new Vue({
          render: h => h(AVarikAnimation, {})
        }).$mount();

        workWithUs.appendChild(component.$el);
      } catch (e) {}
    },
    forceRerender () {
      this.componentKey += 1
    },
    goToForm () {
      const element = document.querySelector('#custom__form')

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    moveCustomForm () {
      const getRefFrom = this.$refs.tastyJobFormRef
      if (!getRefFrom) return;

      const customForm = document.querySelector('.tasty-job #custom__form')
      if (!customForm) return;

      customForm.remove()
      document.querySelector('.content').append(getRefFrom.$el)
    },
    moveCarousel (refTastyJob, sliderId) {
      // Отримуємо референс на компонент слайдера
      const refCarousel = this.$refs[`tastyJobCarousel${sliderId}`]
      if (!refCarousel) {
        console.warn(`No carousel ref found for ${sliderId}`);
        return;
      }

      // Знаходимо оригінальний контейнер з зображеннями
      const originalContainer = refTastyJob.$el.querySelector(`.tasty-job__store__carousel--${sliderId}`)
      if (!originalContainer) {
        console.warn(`No original container found for ${sliderId}`);
        return;
      }

      // Чекаємо наступного тику для впевненості що слайдер відрендерився
      this.$nextTick(() => {
        // Отримуємо DOM елемент слайдера
        const carouselElement = refCarousel.$el
        if (!carouselElement) {
          console.warn(`No carousel element found for ${sliderId}`);
          return;
        }

        // Замінюємо оригінальний контейнер на слайдер
        originalContainer.parentNode.replaceChild(carouselElement, originalContainer)
      })
    },
    prepareImagesForCarousel (refTastyJob, sliderId) {
      const originalContainer = refTastyJob.$el.querySelector(`.tasty-job__store__carousel--${sliderId}`)
      if (!originalContainer) return;

      const images = originalContainer.children
      if (images && images.length > 0) {
        this.slidersData[sliderId] = Array.from(images).map(img => ({
          url: img.src,
          name: ''
        }))

        // Встановлюємо isLoadingCarousel в true після отримання даних
        this.isLoadingCarousel = true
      }
    },
    initializeSliders (refTastyJob) {
      if (!refTastyJob) return;

      ['slider1', 'slider2', 'slider3'].forEach(sliderId => {
        this.prepareImagesForCarousel(refTastyJob, sliderId)
        // Викликаємо moveCarousel після наступного тику
        this.$nextTick(() => {
          this.moveCarousel(refTastyJob, sliderId)
        })
      })
    },
    mapAccordion () {
      try {
        const accordion = this.$refs?.tastyJob?.$el?.querySelector('#accordion');

        if (!accordion) return

        const title = accordion?.previousElementSibling?.classList?.contains('sf-heading__title')
          ? accordion?.previousElementSibling : null

        this.faqTitle = title?.innerText || ''

        if (title) {
          title.classList.add('display-none')
        }

        if (!accordion) {
          return '';
        }
        let accordionItems = accordion.querySelectorAll('.accordion-item');

        const items = [...accordionItems].map((el, i) => {
          const accordionItem = {
            id: i,
            title: this.$t(el.querySelector('.title')?.textContent || ''),
            text: el.querySelector('.accordion-item-text')?.innerHTML || ''
          };

          return accordionItem
        })

        this.faqs = items

        // Replace the div with your Vue component
        if (accordion) {
          const component = new Vue({
            render: h => h(AFaq, {
              props: {
                faqTitle: this.faqTitle,
                faqs: this.faqs
              }
            })
          }).$mount();

          // Replace the div with the rendered component
          accordion.parentNode.replaceChild(component.$el, accordion);
        }
      } catch (e) {}
    }
  },
  mounted () {
    console.log('Component mounted');
    this.$nextTick(() => {
      const refTastyJob = this.$refs.tastyJob
      console.log('refTastyJob in nextTick:', refTastyJob);
      const getBtn = refTastyJob.$el.querySelector('.go-to-form')

      if (getBtn) {
        getBtn.addEventListener('click', this.goToForm)
      }

      this.moveCustomForm()

      if (refTastyJob) {
        this.initializeSliders(refTastyJob)
      }

      this.mapAccordion();
      this.togetherAnimationComponent()
    })

    this.forceRerender()
  },
  beforeMount () {
    if (typeof document === 'undefined') {
      return
    }

    const wrapper = document.createElement('div')
    wrapper.innerHTML = this.content.message

    // this.prepareImagesForCarousel(wrapper)
  },
  beforeDestroy () {
    document.querySelector('.go-to-form')
      .removeEventListener('click', this.goToForm)
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";
@import '~theme/css/fonts';

.tasty-job-page {
  ::v-deep {
    .container {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 0;
    }

    .section {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;

      @media (min-width: $tablet-min) {
        padding: 0;
        max-width: 1190px;
      }

      &--full-width {
        width: 100%;
        max-width: 100%;
      }
    }

    .hero-banner {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin: 0 0 20px;

      @media (min-width: $tablet-min) {
        margin: 0 0 40px;
      }

      &__mobile-image {
        width: 100%;
        object-fit: cover;
        display: block;

        @media (min-width: $tablet-min) {
          display: none;
        }
      }

      &__desktop-video {
        display: none;
        width: 100%;
        height: 100%;

        @media (min-width: $tablet-min) {
          display: block;
        }
      }
    }

    .section__text {
      max-width: 790px;
    }

    .section__text p {
      margin: 0 0 40px;
    }
    .sf-heading {
      display: none;
    }

    .custom__form.custom__form-tasty-job {
      margin-top: 0;
      padding: 0 var(--spacer-10);
      background-color: #B4E103;

      @include only-mobile {
        padding-bottom: var(--spacer-50);
      }

      .custom-form__iframe {
        min-height: 690px;
      }
      .amform-form, .amform-body {
        background: transparent;
      }

      .custom__form-block {
        min-width: inherit;

        @media (min-width: $tablet-min) {
          min-width: 450px;
        }
      }

      .custom__form-description {
        .vacancy-url {
          color: var(--orange);
        }

        p {
          margin: var(--spacer-10) 0 0 0;

          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-15);
          }

          &:last-of-type {
            margin-bottom: var(--spacer-25);

            @media (min-width: $tablet-min) {
              margin-top: var(--spacer-10);
            }
          }
        }
      }

      .sf-heading {
        display: block;

        h4 {
          font-weight: var(--font-medium);
          font-size: var(--font-size-20);
          line-height: var(--font-size-24);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-32);
            line-height: var(--font-size-33);
          }
        }
      }
    }

    .description-box {
      margin: 0 0 50px;

      @media (min-width: $tablet-min) {
        margin: 0 0 60px;
      }

      &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (min-width: $tablet-min) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }
      &__title {
        width: 100%;
        text-align: left;
        margin-bottom: 25px;

        @media (min-width: $tablet-min) {
          width: auto;
        }

        h3 {
          @include header-title;
          margin: 0;
        }
      }
      &__links {
        width: 100%;
        display: flex;
        gap: 10px;
        margin-bottom: 25px;

        @media (min-width: $tablet-min) {
          width: auto;
        }

        .link {
          width: 50%;

          @media (min-width: $tablet-min) {
            width: auto;
          }
        }
      }
      &__cards {
        display: grid;
        grid-template-columns: repeat(2, calc((100% - var(--spacer-10)) / 2));
        grid-gap: var(--spacer-10);
        padding: 0;
        margin: 0;
        list-style: none;

        @include for-desktop {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        position: relative;
        height: auto;
        padding: var(--spacer-20);
        background: var(--light-gray);
      }

      &__image-wrapper {
        height: px2rem(74);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: var(--spacer-10);
        justify-content: flex-end;

        @media (min-width: $tablet-min) {
          height: px2rem(160);
          max-height: inherit;
          padding-bottom: 0;
        }

        img {
          max-width: 110px;
          transition: transform 0.3s ease;
          display: block;

          &:hover {
            transform: scale(1.1);
          }

          @media (min-width: $tablet-min) {
            max-width: 212px;
          }
        }
      }

      &__text {
        font-size:  var(--font-size-13);
        line-height: px2rem(16);
        width: 100%;
        margin-top: var(--spacer-10);
        text-align: center;

        @media (min-width: $tablet-min) {
          font-size:  var(--font-size-16);
          line-height: px2rem(19);
          margin-top: var(--spacer-20);
        }
      }

      &__top-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: var(--spacer-15);

        h2 {
          @include for-mobile {
            font-size: var(--spacer-16);
          }
        }

        @media (min-width: $tablet-min) {
          padding-bottom: var(--spacer-25);
        }
      }
    }

    .tasty-job {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
        padding: 0;
      }

      h2 {
        font-size:  var(--font-size-20);
        line-height: var(--font-size-24);
        font-weight: var(--font-medium);
        color: var(--black);
        margin: 0;

        @media (min-width: $tablet-min) {
          font-size:  var(--font-size-32);
        }
      }

      > div {
        width: 100%;
      }

      .dark-gray {
        color: var(--dark-gray);
        font-size: var(--font-size-16);
      }
    }

    .m-tasty-carousel {
      position: relative;

      .sfo-carousel__bullets {
        display: flex;
        padding-top: 0;
        padding: var(--spacer-25) 0;
        z-index: 1;
        position: relative;

        .sf-bullets > li {
          margin-bottom: 0;
        }

        @media (min-width: $tablet-min) {
          display: none;
        }
      }

      .sf-carousel__controls {
        transform: var(--carousel-controls-transform, translate3d(0, -50%, 0));
        display: var(--carousel-controls-display, none);
        justify-content: var(--carousel-controls-justify-content, space-between);
        --carousel-controls-size: 15rem;
        --carousel-controls-display: flex;
        top: 50%;
        left: 20px;
        right: 20px;
        z-index: 1;
        width: auto;

        @media (max-width: $tablet-max) {
          --carousel-controls-display: none;
        }
      }

      &__item {
        max-width: 100%;

        &-image {
          width: 100%;
          transition: transform 0.3s ease;
          display: block;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .store-list__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 50px;

      @media (min-width: $tablet-min) {
        flex-direction: row;
        margin: 0 0 80px;
      }

      &--reversed {
        @media (min-width: $tablet-min) {
          .text-section {
            order: 1;
          }

          .slider-section {
            order: 0;
          }
        }
      }
    }

    .text-section {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      @media (min-width: $tablet-min) {
        width: 50%;
        padding: 80px 55px;
      }

      &--blue {
        background-color: #5F5CFF;
      }

      &--orange {
        background-color: var(--orange);
      }

      &--violet {
        background-color: #8C01CF;
      }

      &__title {
        @include header-title;
        color: var(--white);

        h3 {
          margin: 0 0 38px;
        }
      }

      &__subtitle {
        font-family: var(--font-family-inter);
        font-size: var(--font-size-20);
        line-height: var(--font-size-24);
        font-weight: var(--font-medium);
        color: var(--white);
        margin: 0;

        h4 {
          margin: 0 0 15px;
        }
      }

      &__list-item {
        color: var(--white);
        font-size: var(--font-size-16);
        line-height: var(--font-size-20);
        font-weight: var(--font-medium);
        margin: 0 0 15px;
        font-family: var(--font-family-inter);
      }

      &__links {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (min-width: $tablet-min) {
          flex-direction: row;
        }
      }
    }
    .slider-section {
      width: 100%;
      box-sizing: border-box;

      @media (min-width: $tablet-min) {
        width: 50%;
      }

      .carousel-items__scrollable-line {
        padding: 0;
      }

      .carousel-items__scrollable-line > * {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }

    .work-with-us {
      display: flex;
      flex-direction: column;
      padding: 40px 20px 0;
      box-sizing: border-box;
      margin: 0 0 50px;
      position: relative;

      background-image: url('/assets/images/cms/tasty-job/work-with-us-mobile.webp');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background: #B4E103;

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/assets/images/cms/tasty-job/work-with-us-mobile@2x.webp');
      }

      @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
        background-image: url('/assets/images/cms/tasty-job/work-with-us-mobile@3x.webp');
      }

      @media (min-width: $tablet-min) {
        padding: 50px 460px 50px 100px;
        margin: 0 0 80px;
      }

      .a-va {
        display: flex;
        justify-content: center;
        margin-top: 36px;

        @media (min-width: $tablet-min) {
          position: absolute;
          right: 100px;
          bottom: 0;
        }
      }

      &__title {
        @include header-title;

        h3 {
          margin: 0 0 10px;

          @media (min-width: $tablet-min) {
            margin: 0 0 20px;
          }
        }
      }

      &__text {
        font-size: var(--font-size-14);
        line-height: var(--font-size-21);
        font-family: var(--font-family-inter);
        margin: 0 0 15px;

        @media (min-width: $tablet-min) {
          max-width: 515px;
        }
      }
      &__links {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (min-width: $tablet-min) {
          flex-direction: row;
        }
      }
    }
    .accordion {
      max-width: px2rem(790);
      width: 100%;
      margin: 0 auto;
    }

    .sf-accordion-item {
      box-sizing: border-box;
      margin: var(--spacer-10) 0;
      position: relative;

      @media (min-width: $tablet-min) {
        margin: var(--spacer-12) 0;
      }

      .sf-accordion-item__header {
        background: var(--light-gray);
        font-size: var(--font-size-14);
        border: 1px solid transparent;
        line-height: var(--spacer-24);
        justify-content: space-between;
        padding: var(--spacer-15) 0 var(--spacer-15) var(--spacer-15);
        text-align: left;
        padding-right: var(--spacer-40);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-18);
        }

        @include font-icon(var(--icon-shape));

        &:before {
          position: absolute;
          right: 0;
          font-size: var(--font-size-12);
          margin: var(--spacer-15);
          height: var(--spacer-15);
          transition: transform 0.2s ease;
          width: var(--spacer-15);

          @media (min-width: $tablet-min) {
            margin-right: var(--spacer-36);
          }
        }

        &.sf-accordion-item__header--open {
          background: transparent;
          border: 1px solid var(--gray3);
          border-bottom: none;

          &:before {
            transform: rotate(180deg);
            transition: transform 0.2s ease;
          }
        }

        @media (min-width: $tablet-min) {
          padding: var(--spacer-30) 0 var(--spacer-30) var(--spacer-30);
        }
      }

      .sf-accordion-item__content {
        border: 1px solid var(--gray3);
        border-top: none;
        padding: 0 var(--spacer-26) var(--spacer-15) var(--spacer-15);

        @media (min-width: $tablet-min) {
          padding: 0 var(--spacer-65) var(--spacer-30) var(--spacer-30);
        }

        .accordion-item-text {
          color: var(--black);
          font-weight: 400;
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-16);
            line-height: var(--spacer-28);
          }
        }
      }
    }

    .faq {
      max-width: 790px;
      width: 100%;
      margin: 0 auto;
    }

    .faq__title {
      @include header-title;
      margin: 0 0 50px;

      @media (min-width: $tablet-min) {
        margin: 0 0 80px;
      }
    }

    .accordion {
      max-width: 790px;
      width: 100%;

      .reception {
        background: var(--light-gray);
        display: flex;
        flex-wrap: wrap;
        margin: 0 var(--spacer-10) var(--spacer-18);
        padding: var(--spacer-30) 0 0 var(--spacer-30);

        @include for-desktop {
          margin-bottom: var(--spacer-50);
        }

        & > span {
          font-size: var(--font-size-14);
          line-height: var(--spacer-16);
          margin-bottom: var(--spacer-15);
          max-width: 360px;
          width: 100%;
          color: var(--dark-gray);
        }

        .reception-telephone {
          color: var(--orange);
          font-size: var(--font-size-18);
          margin-bottom: var(--spacer-30);

          @include for-desktop {
            font-size: var(--font-size-20);
            line-height: var(--spacer-20);
          }
        }
      }
    }

    .link {
      font-size: var(--font-size-14);
      font-weight: var(--font-medium);
      font-family: var(--font-family-inter);
      color: var(--link-color, var(--orange));
      background: var(--link-background, var(--white));
      border: var(--link-border, none);
      border-color: var(--link-border-color, none);
      border-radius: var(--link-border-radius, none);
      text-decoration: none;

      &--button {
        padding: var(--spacer-8) var(--spacer-15);
        --link-background: var(--white);
        --link-border: 1px solid var(--orange);
        --link-border-color: var(--orange);
      }
      &--orange {
        --link-color: var(--orange);
        --link-border-color: var(--orange);
        --link-background: var(--orange);
      }
      &--white {
        --link-color: var(--white);
        --link-border-color: var(--white);
        --link-background: var(--white);
      }
      &--outlined {
        --link-border: 1px solid;
        --link-background: transparent;
      }
      &--filled {
        --link-color: var(--white);

        &.link--white {
          --link-color: var(--black);
        }
      }
    }
  }
}
</style>
